import React from 'react';
import './Projects.css'; // Assuming you have a CSS file for Projects

const Projects = () => {
  return (
    <p>This page is in progress!</p>
  );
};

export default Projects;
